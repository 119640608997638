import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Seotoday from '@solid-ui-blocks/Hero/Block01'
import Unlock from '@solid-ui-blocks/Hero/Block01'
import Features from '@solid-ui-blocks/Features/Block05'
import HowItWorks from '@solid-ui-blocks/FeaturesWithPhoto/Block04'
import Testimonials from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Pricing from '@solid-ui-blocks/Pricing/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Home' />
      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <Hero content={content['hero']} reverse />
      <Container variant='wide' sx={styles.featuresContainer}>
        <Features content={content['features']} />
      </Container>
      <Divider space='4' />
      <Seotoday content={content['seotoday']} />
      <Divider space='4' />
      <Container variant='full' sx={styles.unlockContainer}>
        <Unlock content={content['unlock']} reverse />
      </Container>
      <Divider space='4' />
      <HowItWorks content={content['how-it-works']} />
      <Divider space='4' />
      <Faq content={content['faq']} />
      <Divider space='4' />
      <Pricing content={content['pricing']} />
      <Divider space='4' />
      <Testimonials content={content['testimonials']} />
      <Divider space='4' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query homepageAppBlockContentAndHomepageAppBlockContent {
    allBlockContent(filter: { page: { in: ["site/index", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default IndexPage
